













































































































































































































































































































import Vue from 'vue';

export default Vue.extend({
  name:       'LightBox',
  components: { },
  props:      {
    visible: {
      type: Boolean,
      default: false
    },
    getHeader: {
      type: Function,
      default: () => ''
    },
    getUrl: {
      type: Function,
      default: () => '',
    },
    getFooter: {
      type: Function,
      default: () => '',
    
    },
    getAlt: {
      type: Function,
      default: () => '',
    },
    imageIndex: {
      type: Number,
      default: () => 0
    },
    images: {
      type: Array,
      default: () => [ ]
    },
    galleryMode: {
      type: Boolean,
      default: () => false
    },
  },
  data() {
    return { };
  },
  computed:   {
  },
  created:    () => { },
  methods:    {
    triggerClose(event: any) {
      event.stopPropagation()

      this.$emit('on-close');
    },
    stopPropagation(event: any) {
      event.stopPropagation();
    },

    triggerForward() {
      this.$emit('index-change', (this.$props.imageIndex + 1) % this.$props.images.length);
    },
    triggerBack() {

      if(this.$props.imageIndex === 0) {
        this.$emit('index-change', this.$props.images.length - 1);
      } else {

        this.$emit('index-change', this.$props.imageIndex - 1);
      }
    }
  },
});

